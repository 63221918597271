
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {PENALTY_STORE_NAME, PenaltyStoreActions} from "@/store/penalty.store";
import PenaltyStatus from "@/models/PenaltyStatus";
import CompanyRepository from "@/api/repositories/CompanyRepository";
import PenaltySeries from "@/models/PenaltySeries";
import CompanyIncomeRepository from "@/api/repositories/CompanyIncomeRepository";

const PenaltyStore = namespace(PENALTY_STORE_NAME);

@Component({
  components: {
    PenaltyPaymentListComponent: () => import(
      /* webpackChunkName: "PenaltyPaymentListComponent" */
      '@/components/PenaltyPayment/PenaltyPaymentList.component.vue'
    ),
    PenaltyChartComponent: () => import(
      /* webpackChunkName: "ColumnChartComponent" */
      '@/components/Misc/PenaltyChart.component.vue'
    ),
    CompanyBalanceComponent: () => import(
      /* webpackChunkName: "CompanyBalanceComponent" */
      '@/components/Payment/CompanyBalance.component.vue'
    ),
  }
})
export default class PenaltyPaymentView extends Vue {
  @PenaltyStore.Action(PenaltyStoreActions.GET_ALL)
  private loadPenalties!: (companyId: string) => Promise<PenaltyStatus[]>;

  /**
   * loading flag to indicate if the penalties are being loaded
   */
  private isLoadingPenalties: boolean = false;

  private search: string = '';

  /**
   * loading flag to indicate if the WALLET BALANCE is loading
   * @private
   */
  private isLoadingWallet = false;

  /**
   * loading flag that indicates if the graph is loading
   * @private
   */
  private isLoadingGraph = true;

  /**
   * income model
   * @private
   */
  private income: number | null = null;

  private payments: PenaltyStatus[] = [];

  private stats: PenaltySeries[] = [];

  /**
   * fetches all data simultaneously
   * @private
   */
  private async created() {
    // fetches the income and penalties first
    await Promise.all([
        this.fetchIncome(),
        this.fetchPenalties(),
    ]);

    // fetches chart infos afterwards, since the graph is sometimes taking a lot of performance, we're loading the
    // other data first to render the rest of the view and then loading the graph data afterwards
    await this.fetchChartInfo();
  }

  private async fetchChartInfo() {
    try {
      this.isLoadingGraph = true;
      const { data } = await CompanyRepository.getChartInfoAboutIncomeByCompanyId(this.$route.params.id);
      this.stats = data.map((s: PenaltySeries) => {
        return PenaltySeries.parseFromObject(s);
      });


      // filters the data to only show the last years data (by using the last 365 entries) to avoid old data
      this.stats = this.stats.map((f) => {
        // gets the length of the stat array and tries to use the last 365 values of the entry, if there are less values
        // the maximum number of values is used
        const length = f.data.dates.length;
        const maxLength = Math.max(0, Math.min(length, 365));

        return PenaltySeries.parseFromObject({
          ...f, data: {
            dates: f.data.dates.slice(f.data.dates.length - maxLength, f.data.dates.length),
            values: f.data.values.slice(f.data.values.length - maxLength, f.data.values.length),
          }
        });
      });
    } catch(_) {
      // when there are no statistics this will throw an error, but since we don't want to show an error
      // placeholder but the empty statistic, this is no problem, so there is no need to react on this
      // behaviour
    } finally {
      this.isLoadingGraph = false;
    }
  }

  /**
   * fetches penalties
   */
  private async fetchPenalties() {
    try {
      this.isLoadingPenalties = true;
      // gets the payment based on the current company
      this.payments = await this.loadPenalties(this.$route.params.id);
    } finally {
      this.isLoadingPenalties = false;
    }
  }

  /**
   * fetches the income / balance of the company
   * @private
   */
  private async fetchIncome() {
    try {
      this.isLoadingWallet = true;
      const {data} = await CompanyIncomeRepository.getIncome(this.$route.params.id);
      this.income = data.balance;
    } finally {
      this.isLoadingWallet = false;
    }
  }

  private get xScaling() {
    if(!this.stats || this.stats.length <= 0) return [];
    return this.stats[0]?.data.dates ?? [];
  }
}
