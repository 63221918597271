import Parseable from "@/misc/Parseable";

export default class PenaltyData extends Parseable {
    public dates!: string[];
    public values!: number[];

    public static parseFromObject(object: any): PenaltyData {
        const penaltyData = new PenaltyData();
        Object.assign(penaltyData, object);
        return penaltyData;
    }

    public parseToObject(): Partial<PenaltyData> {
        return { ...this };
    }
}