import Parseable from '@/misc/Parseable';
import PenaltyContainerType from "@/models/PenaltyContainerType";
import PenaltyData from "@/models/PenaltyData";

export default class PenaltySeries extends Parseable {
    public type!: PenaltyContainerType;
    public data!: PenaltyData;

    public static parseFromObject(object: any): PenaltySeries {
        const series = new PenaltySeries();
        Object.assign(series, object);
        series.data = PenaltyData.parseFromObject(series.data);
        return series;
    }

    public parseToObject(): Partial<PenaltySeries> {
        return { ...this };
    }
}
